import { useEffect } from 'react';
import { useLoading } from './LoadingContext';
import { eventEmitter } from './eventEmitter';
import { t } from 'i18next';

const LoadingEventListener = () => {
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    const showLoadingHandler = () => showLoading(t('pleaseWait'));

    eventEmitter.on('show-loading', showLoadingHandler); 
    eventEmitter.on('hide-loading', hideLoading);

    return () => {
      // Clean up the event listeners when the component unmounts
      eventEmitter.off('show-loading', showLoadingHandler);
      eventEmitter.off('hide-loading', hideLoading);
    };
  }, [showLoading, hideLoading]);

  return null; // This component doesn't render anything
};

export default LoadingEventListener;
