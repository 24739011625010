import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import createLogger from './logging';
const { indexlog, indexerror } = createLogger('UserContext');

const UserContext = createContext();

const detectClient = (userAgent) => {
  const ua = userAgent.toLowerCase();
  if (/iphone|ipad|ipod|android/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pushPermission, setPushPermission] = useState(typeof Notification !== 'undefined' ? Notification.permission : 'default');
  const [isPushPermissionModalOpen, setIsPushPermissionModalOpen] = useState(pushPermission !== 'granted');
  const [clientType] = useState(detectClient(navigator.userAgent));

  // Push Notifications
  const checkPushPermission = useCallback(() => {
    if (typeof Notification !== 'undefined') {
      const permission = Notification.permission;
      if (permission !== pushPermission) { 
        setPushPermission(permission);
      }
      if (permission !== 'granted' && !isPushPermissionModalOpen) {
        setIsPushPermissionModalOpen(true);
      } else if (permission === 'granted' && isPushPermissionModalOpen) {
        setIsPushPermissionModalOpen(false);
      }
    } else {
      if (pushPermission !== 'default') {
        setPushPermission('default');
      }
      if (!isPushPermissionModalOpen) {
        setIsPushPermissionModalOpen(true);
      }
    }
  }, [isPushPermissionModalOpen, pushPermission]);

  useEffect(() => {
    // Update permission on mount in case it changes
    indexlog("Checking Push Notification Permissions on startup...")
    checkPushPermission();
    // eslint-disable-next-line 
  }, []);

  const getCookie = useCallback((name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }, []);

  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      indexerror('Failed to parse JWT:', e);
      return null;
    }
  };

  const logout = async () => {
    const token = getCookie('jwtToken');
    if (token) {
      try {
        await fetch('/api/token', {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (error) {
        indexerror('Logout failed:', error);
      }
    }
  
    setUser(null);
    document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      error, 
      setError, 
      successMessage, 
      setSuccessMessage, 
      logout, 
      getCookie, 
      parseJwt, 
      clientType, 
      pushPermission, 
      setPushPermission, 
      checkPushPermission,
      isPushPermissionModalOpen,
      setIsPushPermissionModalOpen
    }}>
      {children}
    </UserContext.Provider>
  );
};
