import React from 'react';
import '../css/iPhoneInstallInstructionsOverlay.css'; // Make sure to style your overlay
import { useTranslation } from 'react-i18next';

const OfflineOverlay = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>{t('offlineOL1')}</h2>
        <h3>{t('offlineOL2')}</h3>
      </div>
    </div>
  );
};

export default OfflineOverlay;
