export const timezones = [
    { label: 'UTC', value: 'UTC' },
    { label: 'London (GMT)', value: 'Europe/London' },
    { label: 'Berlin (CET)', value: 'Europe/Berlin' },
    { label: 'Madrid (CET)', value: 'Europe/Madrid' },
    { label: 'Moscow (MSK)', value: 'Europe/Moscow' },
    { label: 'New York (EST)', value: 'America/New_York' },
    { label: 'Chicago (CST)', value: 'America/Chicago' },
    { label: 'Denver (MST)', value: 'America/Denver' },
    { label: 'Los Angeles (PST)', value: 'America/Los_Angeles' },
    { label: 'Anchorage (AKST)', value: 'America/Anchorage' },
    { label: 'Honolulu (HST)', value: 'Pacific/Honolulu' },
    { label: 'Mumbai (IST)', value: 'Asia/Kolkata' },
    { label: 'Bangkok (ICT)', value: 'Asia/Bangkok' },
    { label: 'China (CST)', value: 'Asia/Shanghai' },
    { label: 'Japan (JST)', value: 'Asia/Tokyo' },
    { label: 'Sydney (AEDT)', value: 'Australia/Sydney' },
    { label: 'Auckland (NZDT)', value: 'Pacific/Auckland' },
    { label: 'Hong Kong (HKT)', value: 'Asia/Hong_Kong' },
    { label: 'Seoul (KST)', value: 'Asia/Seoul' },
    { label: 'Singapore (SGT)', value: 'Asia/Singapore' },
    { label: 'Cairo (EET)', value: 'Africa/Cairo' },
    { label: 'Johannesburg (SAST)', value: 'Africa/Johannesburg' },
    { label: 'São Paulo (BRT)', value: 'America/Sao_Paulo' },
    { label: 'Mexico City (CST)', value: 'America/Mexico_City' },
    { label: 'Dubai (GST)', value: 'Asia/Dubai' },
    { label: 'Istanbul (TRT)', value: 'Europe/Istanbul' },
    { label: 'Jakarta (WIB)', value: 'Asia/Jakarta' },
    { label: 'Karachi (PKT)', value: 'Asia/Karachi' },
    { label: 'Lagos (WAT)', value: 'Africa/Lagos' },
    { label: 'Lima (PET)', value: 'America/Lima' },
    { label: 'Manila (PHT)', value: 'Asia/Manila' },
    { label: 'Buenos Aires (ART)', value: 'America/Argentina/Buenos_Aires' },
    { label: 'Brussels (CET)', value: 'Europe/Brussels' },
    { label: 'Toronto (EST)', value: 'America/Toronto' },
];
