import React, { useState, useEffect } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import { t } from 'i18next';

const Countdown = ({ user }) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    if (!user) return;

    const calculateDeadline = () => {
      const lastVisitTimestamp = new Date(user.lastVisit).getTime();
      const baseTime = lastVisitTimestamp + user.notificationInterval + user.gracePeriod;
      const additionalTime = user.retryPushNotification ? user.gracePeriod : 0;
      return baseTime + additionalTime;
    };

    const updateCountdown = () => {
      const deadline = calculateDeadline();
      const timeLeft = deadline - Date.now();
      if (timeLeft <= 0) {
        setCountdown('00:00:00');
        return;
      }
      const hours = String(Math.floor(timeLeft / (1000 * 60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0');
      setCountdown(`${hours}:${minutes}:${seconds}`);
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown(); // initialize the countdown immediately

    return () => clearInterval(interval); // cleanup interval on component unmount
  }, [user]);

  return <IonItem><IonLabel>{t('countDown1')}{countdown}{t('countDown2')}</IonLabel></IonItem>;
};

export default Countdown;
