import { useEffect } from 'react';
import { useUser } from './UserContext';

const useDynamicCssLoad = () => {
  // Das hier funktioniert!
  const { clientType } = useUser() || { clientType: 'mobile' };

  useEffect(() => {
    const loadCss = async () => {
      try {
        if (clientType === 'mobile') {
          await import('../css/iPhoneInstallInstructionsOverlay.css')
          await import('../css/AndroidInstallInstructionsOverlay.css')
          //await import('../css/mobile.css');
        } else {
          //await import('../css/desktop.css');
        }
      } catch (error) {
        console.error("Error loading CSS", error);
      }
    };

    loadCss();
  }, [clientType]); // Depend on clientType to reload CSS when it changes
};

export default useDynamicCssLoad;
