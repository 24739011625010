import React, { useEffect, useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonPage, IonContent, IonRouterOutlet, IonModal,IonToggle, IonSpinner, IonText, IonIcon, IonButton, IonButtons, IonHeader, IonToolbar, IonTitle, IonList, IonLabel, IonItem, IonNote } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { fitness, planet, arrowForward, alertOutline, checkmark, contract, paw, partlySunny } from 'ionicons/icons';

import { UserProvider, useUser } from './helpers/UserContext.js';
import { LoadingProvider, useLoading } from './helpers/LoadingContext.js';
import LoadingEventListener from './helpers/LoadingEventListener';
import useDynamicCssLoad from './helpers/dynamicCssLoad.js';
import IPhoneInstallInstructionsOverlay from './helpers/IPhoneInstallInstructionsOverlay.js';
import AndroidInstallInstructionsOverlay from './helpers/AndroidInstallInstructionsOverlay.js';
import OfflineOverlay from './helpers/OfflineOverlay.js';
import './i18n';
import LoginForm from './LoginForm';
import MainPage from './MainPage';
import RegistrationForm from './RegistrationForm';

import '@ionic/react/css/core.css';
//import { t } from 'i18next';
// Other CSS imports...

setupIonicReact();

function App() {
  useDynamicCssLoad();
  

  const checkStandaloneMode = () => {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
  };

  const checkShowAbout = () => {
    const storedValue = localStorage.getItem('showAboutModal');
    if (checkStandaloneMode()) {
      return false;
    }
    return storedValue !== null ? storedValue === 'true' : true;
  }

  const { t } = useTranslation();
  const { checkPushPermission } = useUser();
  const isStandalone = checkStandaloneMode();
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  const [showIPhoneOverlay, setShowIPhoneOverlay] = useState(isIOS && !isStandalone);
  const [showAndroidOverlay, setShowAndroidOverlay] = useState(isAndroid && !isStandalone);
  const [showOfflineOverlay, setShowOfflineOverlay] = useState(!navigator.onLine);
  const [showAboutModal, setshowAboutModal] = useState(() => checkShowAbout());
  const [showHowItWorksModal, setshowHowItWorksModal] = useState(false);

  const clearBadge = useCallback(() => {
    if (!navigator.onLine) {
      console.log('Device is offline, badge will not be cleared.');
      return; // Exit the function if offline
    }
    if ('clearAppBadge' in navigator) {
      navigator.clearAppBadge().catch(error => {
        console.error('Failed to clear badge:', error);
      });
    }
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      console.log("Clearing badge and checking push permissions...");
      if (isStandalone) {
        checkPushPermission();
        clearBadge();
      }
    }
  }, [isStandalone, checkPushPermission, clearBadge]);

  const handleNetworkChange = useCallback(() => {
    const online = navigator.onLine;
    setShowOfflineOverlay(!online);
    if (online) {
      console.log('Device is back online, attempting to clear badge...');
      clearBadge();
    } else {
      console.log('Device is offline.');
    }
  }, [clearBadge]);

  useEffect(() => {
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [handleNetworkChange, handleVisibilityChange]);

  const handleCloseOverlay = () => {
    setShowIPhoneOverlay(false);
    setShowAndroidOverlay(false);
    setShowOfflineOverlay(false);
  };

  useEffect(() => {
    localStorage.setItem('showAboutModal', showAboutModal);
  }, [showAboutModal]);

  return (
    <UserProvider>
      <LoadingProvider>
        <IonApp>
          <GlobalLoading />
          <LoadingEventListener />
          <IonReactRouter>
            <IonPage>
              {showIPhoneOverlay && <IPhoneInstallInstructionsOverlay onClose={handleCloseOverlay} />}
              {showAndroidOverlay && <AndroidInstallInstructionsOverlay onClose={handleCloseOverlay} />}
              {showOfflineOverlay && <OfflineOverlay onClose={handleCloseOverlay} />}

              {/* Modal Component */}
              <IonModal isOpen={showAboutModal} onDidDismiss={() => setshowAboutModal(false)}>
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>{t('aboutPingAliveModal.aboutPingAlive')}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton onClick={() => setshowAboutModal(false)}>{t('close')}</IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <IonList lines="none">
                    <IonItem><strong>{t('aboutPingAliveModal.whatIsPingAlive')}</strong></IonItem> 
                    <IonList inset={true} lines="inset">
                      <IonItem>
                        <IonIcon slot="start" icon={paw}></IonIcon>
                        {t('aboutPingAliveModal.reason1')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={planet}></IonIcon>
                        {t('aboutPingAliveModal.reason2')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={fitness}></IonIcon>
                        {t('aboutPingAliveModal.reason3')}
                      </IonItem>
                    </IonList>
                    <IonItem><IonNote>{t('aboutPingAliveModal.note1')}</IonNote></IonItem>
                  </IonList>
                  <IonList lines="none">
                    <IonItem button onClick={() => setshowHowItWorksModal(true)}><IonLabel><strong>{t('aboutPingAliveModal.howDoesItWork')}</strong></IonLabel></IonItem>
                    <IonList inset={true} lines="inset">
                      <IonItem>
                        <IonIcon slot="start" icon={checkmark}></IonIcon>
                        {t('aboutPingAliveModal.explain1')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={contract}></IonIcon>
                        {t('aboutPingAliveModal.explain2')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={partlySunny}></IonIcon>
                        {t('aboutPingAliveModal.explain3')}
                      </IonItem>
                    </IonList>
                  </IonList>
                  <IonList lines="none">
                    <IonItem><IonLabel><strong>{t('aboutPingAliveModal.whatElseDoYouNeed')}</strong></IonLabel></IonItem>
                    <IonList inset={true} lines="inset">
                      <IonItem>
                        <IonIcon slot="start" icon={arrowForward}></IonIcon>
                        {t('aboutPingAliveModal.require1')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={arrowForward}></IonIcon>
                        {t('aboutPingAliveModal.require2')}
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={alertOutline}></IonIcon>
                        {t('aboutPingAliveModal.require3')}
                      </IonItem>
                    </IonList>
                    <IonItem><IonNote>{t('aboutPingAliveModal.note3')}</IonNote></IonItem>
                    <IonItem>
                      <IonToggle
                        checked={showAboutModal}
                        onIonChange={() => setshowAboutModal(false)}
                      >
                        {t('aboutPingAliveModal.hidePermanent')}
                      </IonToggle>
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonModal>
              <IonModal isOpen={showHowItWorksModal}>
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>{t('aboutPingAliveModal.howDoesItWork')}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton onClick={() => setshowHowItWorksModal(false)}>{t('close')}</IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail1')}</p></IonItem>
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail2')}</p></IonItem>
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail3')}</p></IonItem>
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail4')}</p></IonItem>
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail5')}</p></IonItem>
                  <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail6')}</p></IonItem>
                </IonContent>
              </IonModal>
              <IonContent>
                <IonRouterOutlet>
                  <Route path="/" exact component={MainPage} />
                  <Route path="/login" component={LoginForm} />
                  <Route path="/register" component={RegistrationForm} />
                  {/* Additional routes as needed */}
                </IonRouterOutlet>
              </IonContent>
            </IonPage>
          </IonReactRouter>
        </IonApp>
      </LoadingProvider>
    </UserProvider>
  );
}

const GlobalLoading = () => {
  const { isLoading, hideLoading, message } = useLoading();

  return (
      <IonModal isOpen={isLoading} onDidDismiss={hideLoading} backdropDismiss={false}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <IonSpinner name="crescent" />
              <IonText>{message}</IonText>
          </div>
      </IonModal>
  );
};

export default App;
