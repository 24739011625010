import React, { useEffect, useState } from 'react';
import { IonList, IonItem, IonLabel, IonButton, IonToast, IonIcon,IonNote } from '@ionic/react';
import { walk } from 'ionicons/icons';
import { useUser } from './UserContext'; // Import the useUser hook
import { t } from 'i18next';
import createLogger from './logging';
const { indexlog, indexerror } = createLogger('UserList');

const UserList = ({ users = [], labels = [], onRemove, showRemoveButton = false, alert = false }) => {
  const { getCookie, logout } = useUser(); // Using useUser hook to access getCookie and other context data
  const [userStatuses, setUserStatuses] = useState({});
  const [criticalUsers, setCriticalUsers] = useState([]); // Track all users with status 2
  const [toastQueue, setToastQueue] = useState([]); // Queue of toasts to be shown
  const [currentToast, setCurrentToast] = useState(null); // The currently displayed toast

  useEffect(() => {
    const fetchStatuses = async () => {
      const token = getCookie('jwtToken');
      if (!token) {
        indexerror('No JWT token found');
        await logout();
        window.location.href = '/';
        return;
      }
  
      try {
        indexlog('Fetching latest user statuses (on change or every 60s)');
        
        const statusPromises = users.map(async (userId) => {
          try {
            const response = await fetch(`/api/user-public/${userId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            });
  
            if (response.status === 401) {
              indexerror(`Unauthorized request for user ${userId}. Logging out...`);
              await logout();
              window.location.href = '/';
              return;  // Stop further execution since the user is logged out and redirected
            }

            if (response.status === 404) {
              indexlog(`User not found for userId ${userId}. Setting status to -1.`);
              return { userId, status: 3 };  // Set the status to -1 for 404 (User not found)
            }
  
            if (!response.ok) {
              throw new Error('Failed to fetch status data');
            }
  
            const data = await response.json();
            return { userId, status: data.status };
  
          } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('fetch')) {
              // Handle network errors specifically
              indexlog(`Network error occurred while fetching status for user ${userId}. Check your connection.`);
            } else {
              indexerror(`Failed to fetch status for user ${userId}:`, error);
            }
            return { userId, status: 'unknown' }; // Assign a placeholder or default status if fetch fails
          }
        });
  
        const statuses = await Promise.all(statusPromises);
  
        setUserStatuses(statuses.reduce((acc, { userId, status }) => {
          acc[userId] = status;
          return acc;
        }, {}));
  
      } catch (error) {
        indexerror('Failed to fetch user statuses:', error);
      }
    };
  
    if (users.length > 0) {
      fetchStatuses();
      const intervalId = setInterval(fetchStatuses, 60000); // Update every minute

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [users, setUserStatuses,getCookie,logout]); // Ensure the effect runs when `users` or `setUserStatuses` changes
  
  useEffect(() => {
    const newCriticalUsers = users.filter(userId => userStatuses[userId] === 2);

    // Only update criticalUsers and toastQueue if there are new critical users
    if (newCriticalUsers.length > 0 && JSON.stringify(newCriticalUsers) !== JSON.stringify(criticalUsers)) {
      const newToasts = newCriticalUsers
        .filter(userId => !criticalUsers.includes(userId))
        .map(userId => {
          const userLabel = labels[users.indexOf(userId)] || 'Unknown User';
          return { userId, message: t('userAlertToastMessage', { userLabel }) };
        });

      setCriticalUsers(newCriticalUsers);
      setToastQueue(prevQueue => [...prevQueue, ...newToasts]);
    }

    // Update criticalUsers state to remove any users that no longer have status 2
    if (criticalUsers.length > 0) {
      const updatedCriticalUsers = criticalUsers.filter(userId => userStatuses[userId] === 2);
      if (JSON.stringify(updatedCriticalUsers) !== JSON.stringify(criticalUsers)) {
        setCriticalUsers(updatedCriticalUsers);
      }
    }
  }, [userStatuses, users, labels, criticalUsers]);

  useEffect(() => {
    // If there is no toast currently displayed and there are toasts in the queue, display the next one
    if (!currentToast && toastQueue.length > 0) {
      setCurrentToast(toastQueue[0]);
      setToastQueue(prevQueue => prevQueue.slice(1));
    }
  }, [toastQueue, currentToast]);

  const getStatusColor = (status) => {
    switch (status) {
      case 0: return 'green';
      case 1: return 'blue';
      case 2: return 'red';
      case 3: return 'grey';
      default: return 'blue'; // Fallback color
    }
  };

  if (users.length === 0) {
    return null; // Render nothing if the users array is empty
  }

  // Combine users with labels
  const combined = users.map((user, index) => ({
    userId: user,
    label: labels[index],
  }));

  // Sorting users by their statuses in descending order, with unknown statuses at the bottom
  const sortedCombined = combined.slice().sort((a, b) => {
    const statusA = userStatuses[a.userId];
    const statusB = userStatuses[b.userId];

    const isValidStatus = (status) => [0, 1, 2, 3].includes(status);

    if (!isValidStatus(statusA)) return 1; // Move unknown statuses to the bottom
    if (!isValidStatus(statusB)) return -1; // Move unknown statuses to the bottom

    return statusB - statusA; // Sort valid statuses in descending order
  });

  // Extract sorted users and labels
  const sortedUsers = sortedCombined.map(item => item.userId);
  const sortedLabels = sortedCombined.map(item => item.label);

  /*return (
    <div>
      <IonList>
        {sortedUsers.map((userId, index) => (
          <IonItem key={index} style={{ color: getStatusColor(userStatuses[userId]) }}>
            <IonLabel>
              {(sortedLabels[index]) ? sortedLabels[index] : 'No label'}
            </IonLabel>
            {showRemoveButton && (
              <IonButton onClick={() => onRemove(userId)} color="danger">
                {t('remove')}
              </IonButton>
            )}
          </IonItem>
        ))}
      </IonList>

      {alert && (
        <IonToast
          isOpen={!!currentToast}
          message={currentToast?.message}
          duration={3000}
          position="top"
          color="danger"
          swipeGesture="vertical"
          onDidDismiss={() => setCurrentToast(null)}
        />
      )}
    </div>
  );*/
  return (
    <div>
      <IonList>
        {sortedUsers.map((userId, index) => (
          <React.Fragment key={index}>
            <IonItem style={{ color: getStatusColor(userStatuses[userId]) }}>
              {userStatuses[userId] !== 3 || showRemoveButton ? (
                <IonLabel>
                  {(sortedLabels[index] ? sortedLabels[index] : t('noLabel'))}
                </IonLabel>
              ) : null}
              {userStatuses[userId] === 3 && !showRemoveButton && (
                <>
                  <IonLabel>
                    {(sortedLabels[index] ? sortedLabels[index] : t('noLabel'))}
                  </IonLabel>
                  <IonNote slot="end">{t('userDeleted')}</IonNote>
                  <IonIcon color="danger" slot="end" aria-hidden="true" icon={walk} ></IonIcon>           
                </>
              )}
              {showRemoveButton && (
                <IonButton onClick={() => onRemove(userId)} color="danger">
                  {t('remove')}
                </IonButton>
              )}
            </IonItem>   
          </React.Fragment>
        ))}
      </IonList>
  
      {alert && (
        <IonToast
          isOpen={!!currentToast}
          message={currentToast?.message}
          duration={3000}
          position="top"
          color="danger"
          swipeGesture="vertical"
          onDidDismiss={() => setCurrentToast(null)}
        />
      )}
    </div>
  );
  

};

export default UserList;
