import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from './helpers/UserContext.js';
import { useLoading } from './helpers/LoadingContext';
import { useHistory, useLocation } from 'react-router-dom';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonInput, IonButton, IonButtons, IonFooter, IonText, IonItem, IonIcon, IonModal, IonImg, IonLabel } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { t } from 'i18next';
import { subscribeToPush } from './actions/subscribeToPush';
import createLogger from './helpers/logging';

const RegistrationForm = () => {
  const { indexlog, indexerror } = createLogger('RegistrationForm');
  const [eMail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { showLoading, hideLoading } = useLoading();
  const { setUser, error, setError, setSuccessMessage, checkPushPermission } = useUser();
  const [timezone, setTimezone] = useState('');
  const redirect = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);

  // Reset form and clear errors when the component is mounted
  const resetForm = useCallback(() => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setPasswordError('');
    setError('');
    setUser('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    resetForm();
  }, [location.pathname, resetForm]);

  useEffect(() => {
    // Check if both password fields are non-empty to avoid premature error messages
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setPasswordError(t('passwordsDoNotMatch'));
      } else {
        setPasswordError(''); // Clear error as soon as passwords match
      }
    } else {
      setPasswordError(''); // Also clear error if either field is cleared
    }
  }, [password, confirmPassword]);

  const sendRegistrationData = async (subscription) => {
    try {
      const loginEmail = eMail.toLowerCase();;
      const loginPassword = password;
      const subscriptionData = subscription ? subscription : [];

      const registrationResponse = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eMail: loginEmail, password, subscriptionData, timezone }),
      });

      const registrationData = await registrationResponse.json();

      if (!registrationResponse.ok) {
        throw new Error(registrationData.error || 'Unknown error during registration');
      }

      // Attempt to log the user in after successful registration
      const loginResponse = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ loginEmail, loginPassword }),
      });

      const loginData = await loginResponse.json();

      // Check if login was not successful
      if (!loginResponse.ok) {
        throw new Error(loginData.error || 'Login failed after registration');
      }

      setUser(registrationData);
      storeTokenInCookie(loginData.token);
      setSuccessMessage(`Welcome, ${registrationData.eMail}! Your UUID is ${registrationData.id}`);
      return true;
      
    } catch (error) {
      indexerror('Error sending registration data:', error);
      setError(error.message);
      return false;
    }
  };

  const storeTokenInCookie = (token) => {
    const now = new Date();
    now.setTime(now.getTime() + (24 * 60 * 60 * 1000));
    document.cookie = `jwtToken=${token}; expires=${now.toUTCString()}; path=/; Secure; SameSite=Strict`;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    showLoading(t('pleaseWait'));
    
    if (password !== confirmPassword) {
      hideLoading();
      return;
    }
    try {
      const permission = await Notification.requestPermission();
      indexlog('Requested Push Notification Permission:', permission);
      if (permission === 'granted') {    
        // create empty user object
        const user = {
          eMail: eMail,
          subscriptionDetails: []
        };
        const subscription = await subscribeToPush(user);
        const registrationResult = await sendRegistrationData(subscription);
        if (!registrationResult) {
          return;
        }
        checkPushPermission();
        redirect.push('/');
      } else {
        const registrationResult = await sendRegistrationData([]);
        if (!registrationResult) {
          return;
        }
        //checkPushPermission();
        redirect.push('/');
      }
    } catch (error) {
      indexerror('Error during registration process:', error);
      setError(error.message); // Set error to display in UI
      return;
    } finally {
      hideLoading();
    }
  };

  const handleModalOkClick = () => {
    setShowModal(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonImg 
              src="/ping-alive-logo/logo192.png" 
              alt="logo-image" 
              style={{ height: '64px'}}
            />
          </IonTitle>
        </IonToolbar>       
      </IonHeader>
      <IonContent>
        <IonModal isOpen={showModal} backdropDismiss={false}>
          <div style={{ padding: '20px' }}>
            <h2>{t('beforeRegisterModalInstructions.title')}</h2>
            <p>{t('beforeRegisterModalInstructions.message1')}</p>
            <p>{t('beforeRegisterModalInstructions.message2')}</p>
            <p><strong>{t('beforeRegisterModalInstructions.warning')}</strong></p>
            <IonButton expand="block" onClick={handleModalOkClick}>
              {t('beforeRegisterModalInstructions.okButton')}
            </IonButton>
          </div>
        </IonModal>
        <IonItem>
          <IonLabel><strong>{t('register')}</strong></IonLabel>
        </IonItem>
        <form onSubmit={handleRegister}>
          <IonItem>
            <IonInput
              type="email"
              value={eMail}
              clearInput={true}
              onIonInput={(e) => setEmail(e.detail.value)}
              placeholder={t('email')}
              required
            />
          </IonItem>
          <IonItem>
            <IonInput
              type="password"
              value={password}
              clearInput={true}
              onIonInput={(e) => setPassword(e.detail.value)}
              placeholder={t('password')}
              required
            />
          </IonItem>
          <IonItem>
            <IonInput
              type="password"
              value={confirmPassword}
              clearInput={true}
              onIonInput={(e) => setConfirmPassword(e.detail.value)}
              placeholder={t('confirmPassword')}
              required 
            />
          </IonItem>
          {passwordError && 
            <IonItem>
              <IonText color="danger">{passwordError}</IonText>
            </IonItem>
          }
          {error && 
            <IonItem>
              <IonText color="danger">{error}</IonText>
            </IonItem>
          }
          <IonButton expand="block" type="submit">{t('register')}</IonButton>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="outline" onClick={() => redirect.push('/', { fromRegistrationPageBackButton: true })}>
              {t('back')}
              <IonIcon slot="start" icon={arrowBack}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default RegistrationForm;
