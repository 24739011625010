import { useRef } from 'react';
import createLogger from './logging';

function useLogger(source) {
  const loggerRef = useRef(createLogger(source));

  return {
    indexlog: loggerRef.current.indexlog,
    indexerror: loggerRef.current.indexerror,
  };
}

export default useLogger;
