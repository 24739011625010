const getTokenExpirationDate = (token, parseJwt) => {
    const decoded = parseJwt(token);
    if (!decoded.exp) {
      return null;
    }
  
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  };
  
  const isTokenExpiringSoon = (token, parseJwt) => {
    const expirationDate = getTokenExpirationDate(token, parseJwt);
    if (!expirationDate) {
      return false;
    }
    return expirationDate.getTime() - Date.now() < 6 * 60 * 60 * 1000;
  };
  
  export default isTokenExpiringSoon;
  