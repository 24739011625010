import { updateUserSettings } from '../helpers/updateUserSettings';

const handleAliveButtonClick = async (setUser, getCookie, parseJwt) => {
  try {
    await updateUserSettings('userStatus', 0, getCookie, parseJwt);
    setUser((prevUser) => ({ ...prevUser, userStatus: 0 }));
  } catch (error) {
    console.error('Error updating user status:', error);
  }
};

export default handleAliveButtonClick;