import React, { useState, useEffect } from 'react';

const ConsoleDisplay = () => {
  const [logs, setLogs] = useState([]);

  const loadLogs = () => {
    const storedLogs = JSON.parse(localStorage.getItem('last100Logs')) || [];
    setLogs(storedLogs.reverse()); // Reverse the logs to display the newest first
  };

  useEffect(() => {
    loadLogs(); // Load logs initially

    const handleStorageChange = () => {
      loadLogs(); // Reload logs if there's a change in localStorage
    };

    // Listen for changes to localStorage
    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      {logs.map((message, index) => (
        <div key={index} style={{ color: 'black' }}>
          <p>LOG: {typeof message === 'string' ? message : JSON.stringify(message)}</p>
        </div>
      ))}
    </div>
  );
};

export default ConsoleDisplay;
