import { updateUserSettings } from '../helpers/updateUserSettings';

const handleDatePickerChange = async (settingName, newValue, setUser, getCookie, parseJwt) => {
  let valueInMilliseconds = newValue === "testing" ? 10000 : newValue * 60 * 60 * 1000;

  try {
    await updateUserSettings(settingName, valueInMilliseconds, getCookie, parseJwt);
    setUser((prevUser) => ({
      ...prevUser,
      [settingName]: valueInMilliseconds,
    }));
  } catch (error) {
    console.error('Error updating settings:', error);
  }
};

export default handleDatePickerChange;
