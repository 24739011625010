import React, { useState } from 'react';
import { IonItem, IonInput, IonButton, IonText } from '@ionic/react';
import { useUser } from './UserContext';
import addToUserList from '../actions/addToUserList';
import { t } from 'i18next';

const UserInput = ({ arrayName, setNewAlertId, setNewAlertLabel, setNewMonitorId, setNewMonitorLabel }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const { user, setUser, getCookie, parseJwt } = useUser();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(null); // Clear error message when the input changes
  };

  const handleSearch = async () => {
    // Basic email pattern check
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Invalid email format');
      return;
    }

    try {
      const token = getCookie('jwtToken');
      const eMail = email.toLowerCase();
      const response = await fetch('/api/findUUID', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ email: eMail }),
      });

      if (response.ok) {
        setError('');
        const data = await response.json();
        await addToUserList(
          arrayName,
          data.uuid,
          eMail, // Using email as the label
          getCookie,
          parseJwt,
          setUser,
          setNewAlertId,
          setNewAlertLabel,
          setNewMonitorId,
          setNewMonitorLabel,
          user
        );
        setEmail(''); // Clear the input
      } else if (response.status === 404) {
        setError('Email address not found');
      } else {
        setError('An error occurred while searching');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Internal server error');
    }
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="user-input">
      <IonItem>
        <IonInput aria-label={t('enterEmailAddress')} placeholder={t('enterEmailAddress')}
          type="email"
          inputmode="email"
          clearInput={true}
          value={email}
          onIonInput={handleEmailChange}
          onKeyDown={onEnter}
          required
        />
        <IonButton onClick={handleSearch} expand="block">
          {t('add')}
        </IonButton>
      </IonItem>
      {error && (
        <IonItem>
          <IonText color="danger">
            <p>{error}</p>
          </IonText>
        </IonItem>
      )}
    </div>
  );
};

export default UserInput;
