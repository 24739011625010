import { updateUserSettings } from '../helpers/updateUserSettings';

const toggleSetting = async (settingName, user, setUser, getCookie, parseJwt) => {
  try {
    const newValue = !user[settingName];
    setUser((prevUser) => ({ ...prevUser, [settingName]: newValue }));
    await updateUserSettings(settingName, newValue, getCookie, parseJwt);
  } catch (error) {
    console.error('Error toggling setting:', error);
  }
};

export default toggleSetting;