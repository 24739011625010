// Assuming updateUserSettings is imported from another file
import { updateUserSettings } from '../helpers/updateUserSettings'; // Adjust path as needed

/**
 * Function to handle adding a new user ID to a specified array and updating settings.
 * @param {string} arrayName - The name of the array to update.
 * @param {string} newUserId - The new user ID to add.
 * @param {string} newUserLabel - The label associated with the new user ID.
 * @param {function} getCookie - Function to get a cookie value.
 * @param {function} parseJwt - Function to parse a JWT.
 * @param {function} setUser - Function to update user state.
 * @param {function} setNewAlertId - Function to reset the new alert ID.
 * @param {function} setNewAlertLabel - Function to reset the new alert label.
 * @param {function} setNewMonitorId - Function to reset the new monitor ID.
 * @param {function} setNewMonitorLabel - Function to reset the new monitor label.
 * @param {string} user - The current user context.
 */
const addToUserList = async (
  arrayName,
  newUserId,
  newUserLabel,
  getCookie,
  parseJwt,
  setUser,
  setNewAlertId,
  setNewAlertLabel,
  setNewMonitorId,
  setNewMonitorLabel,
  user
) => {
    if (!newUserId) return;

    const token = getCookie('jwtToken');
    if (!token) {
      window.location.href = '/';
      return;
    }
    
    const labelsArrayName = `${arrayName}Labels`;
    const updatedArray = [...new Set([...user[arrayName], newUserId])];
    const updatedLabelsArray = [...user[labelsArrayName]];

    const index = user[arrayName].indexOf(newUserId);
    if (index === -1) {
      updatedLabelsArray.push(newUserLabel || '');
    } else {
      updatedLabelsArray[index] = newUserLabel || '';
    }

    try {
      await updateUserSettings(arrayName, updatedArray, getCookie, parseJwt);
      await updateUserSettings(labelsArrayName, updatedLabelsArray, getCookie, parseJwt);
      setUser((prevUser) => ({
        ...prevUser,
        [arrayName]: updatedArray,
        [labelsArrayName]: updatedLabelsArray,
      }));

      if (arrayName === 'userIdsToAlert') {
        setNewAlertId('');
        setNewAlertLabel('');
        await fetch(`/api/user-public/${newUserId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ id: user.id, eMail: user.eMail }),
        });
      } else if (arrayName === 'userIdsToMonitor') {
        setNewMonitorId('');
        setNewMonitorLabel('');
      }
    } catch (error) {
      console.error('Error updating settings:', error);
    }
};

export default addToUserList;
