import React from 'react';
import { IonList, IonItem, IonLabel, IonButton,IonIcon } from '@ionic/react';
import { tabletPortrait } from 'ionicons/icons';
import { t } from 'i18next';
import HelpPopover from './HelpPopOver';

const getBrowserFromEndpoint = (endpoint) => {

  if (!endpoint) {
    return 'Other Browser';
  }
  
  if (endpoint.startsWith('https://fcm.googleapis.com')) {
    return 'Chrome';
  } else if (endpoint.startsWith('https://updates.push.services.mozilla.com')) {
    return 'Firefox';
  } else if (endpoint.startsWith('https://web.push.apple.com')) {
    return 'Safari';
  } else {
    return 'Other Browser';
  }
};

const SubscriptionList = ({ subscriptions, onRemove }) => {
  if (subscriptions.length === 0) {
    return null; // Render nothing if the subscriptions array is empty
  }

  // Retrieve the device_id from localStorage
  const storedDeviceId = localStorage.getItem('device_id');

  return (
    <div>
      <IonList>
        <IonItem>
          <IonLabel><strong>{t('myPushSubscriptions')}</strong></IonLabel>
          <HelpPopover keyword="helpSubscriptionList" />
        </IonItem>
        {subscriptions.map((subscription, index) => {
          const { endpoint, deviceId, deviceDetails } = subscription;

          // Determine the label based on whether this is the current device or a legacy subscription
          let label;

          if (deviceId && deviceId === storedDeviceId) {
            label = (
              <>
                <IonIcon color="medium" slot="start" aria-hidden="true" icon={tabletPortrait} />
                {"\u00A0\u00A0"}
                {t('thisDevice')}
              </>
            );
          } else if (deviceDetails) {
            label = `${getBrowserFromEndpoint(endpoint)} ${t('runningOn')} ${deviceDetails.platform}`;
          } else {
            label = `${getBrowserFromEndpoint(endpoint)}`;
          }

          return (
            <IonItem key={index}>
              <IonLabel>{label}</IonLabel>
              <IonButton onClick={() => onRemove(index)} color="danger">
                {t('remove')}
              </IonButton>
            </IonItem>
          );
        })}
      </IonList>
    </div>
  );
};

export default SubscriptionList;
