const changePassword = async (user, newPassword, confirmPassword, getCookie, setShowChangePasswordModal, setPasswordError, setNewPassword, setConfirmPassword, t) => {

  if (!newPassword || !confirmPassword) {
    setPasswordError(t('passwordEmpty'));
    return;
  }
    
  if (newPassword !== confirmPassword) {
    setPasswordError(t('passwordMismatch'));
    return;
  }

  try {
    const token = getCookie('jwtToken');
    const response = await fetch(`/api/user-details/${user.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ settingName: 'password', newValue: newPassword }),
    });
    //console.log(JSON.stringify({ settingName: 'password', newValue: newPassword }));

    if (response.ok) {
      setShowChangePasswordModal(false);
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
    } else {
      const errorData = await response.json();
      setPasswordError(errorData.error);
    }
  } catch (error) {
    console.error('Error changing password:', error);
    setPasswordError(t('passwordChangeError'));
  }
};
  
  export default changePassword;
  