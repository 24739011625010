import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend'; // if you want to load translations from a server
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Load translations from a server
  .use(LanguageDetector) // Detects language from browser settings
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: 'en', // Use English as a fallback language
    debug: false,
    load: 'languageOnly', // Load only the language code part ('de' instead of 'de-DE')
    supportedLngs: ['en', 'de', 'it', 'es', 'fr'], // Specify supported languages
    nonExplicitSupportedLngs: true, 
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    react: {
      wait: false, // Ensures that the translate HOC or hook waits for the translations to be loaded
      useSuspense: true, // Optional: This can be set to true to handle fallbacks when loading 
    }
  });

export default i18n;
