import { updateUserSettings } from '../helpers/updateUserSettings';

export const removeFromSubscriptionList = async (index, subscriptions, user, setUser, getCookie, parseJwt) => {
  try {
    let updatedSubscriptions;
    if (index === 'all') {
      updatedSubscriptions = [];  // Clear all subscriptions
    } else {
      updatedSubscriptions = subscriptions.filter((_, i) => i !== index);
    }
    await updateUserSettings('subscriptionDetails', updatedSubscriptions, getCookie, parseJwt);
    setUser({ ...user, subscriptionDetails: updatedSubscriptions });
    return updatedSubscriptions;
  } catch (error) {
    console.error('Failed to remove subscription', error);
    throw error;
  }
};